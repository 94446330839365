<template>
  <div
    class="lesson-component-layout flex-container text-component-layout-fix"
    :class="component.paragraph.type"
  >
    <span class="component-count">{{ componentCount }}</span>
    <div
      class="image-placeholder"
      :class="[component.paragraph.imagesOrientation]"
    ></div>
    <section class="content-section flex-container">
      <div class="title-placeholder"></div>
      <div class="text-placeholder"></div>
    </section>
    <span class="legend centered">{{ $t(component.paragraph.text) }}</span>
    <div class="component-controls flex-container">
      <img
        :src="arrowUpIcon"
        :style="{ opacity: arrowUpDisabledStyle }"
        @click="changeComponentOrder(component.order - 1)"
      />
      <img
        :src="arrowDownIcon"
        :style="{ opacity: arrowDownDisabledStyle }"
        @click="changeComponentOrder(component.order + 1)"
      />
      <span class="" @click="deleteComponent(component)">{{
        $t("delete")
      }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "LessonComponent",
  props: {
    component: Object,
    componentCount: Number,
  },
  methods: {
    changeComponentOrder(newOrder) {
      if (newOrder === 0 || newOrder > this.components.length) return;
      this.$store.commit("changeLessonBuilderComponentOrder", {
        component: this.component,
        newOrder: newOrder,
      });

      this.$store.commit("updateLessonBuilderComponentOrder");
      this.$store.dispatch("reoderLessonComponent", this.component);
    },
    deleteComponent() {
      this.$store.commit("updateLoadingStatus", true);
      setTimeout(() => {
        this.$store
          .dispatch("removeLessonBuilderComponent", this.component)
          .then(() => {
            this.$store.commit("deleteLessonBuilderComponent", this.component);
            this.$store.commit("updateCurrentBackOfficeComponent", undefined);
            this.$store.commit("updateLoadingStatus", false);
          });
      }, 500);
    },
  },
  computed: {
    arrowUpIcon() {
      return require("@/assets/icons/arrow_upward.svg");
    },
    arrowDownIcon() {
      return require("@/assets/icons/arrow_downward.svg");
    },
    arrowUpDisabledStyle() {
      return this.component.order === 1 ? "0.3" : "1";
    },
    arrowDownDisabledStyle() {
      return this.component.order === this.components.length ? "0.3" : "1";
    },
    components() {
      return this.$store.state.LessonBuilderComponents;
    },
  },
};
</script>
<style lang="less" scoped>
.lesson-component-layout {
  height: 136px;
  min-height: 136px;
  min-width: 704px;
  width: 704px;
  background-color: #eaebff;
  box-shadow: var(--primary-shadow);
  cursor: pointer;
  position: relative;
  margin: 16px auto !important;
  z-index: 1;
  .component-count {
    margin-top: 5px;
    margin-left: 10px;
    font-weight: bold;
    position: absolute;
  }
  div:not(.component-controls) {
    background-color: rgba(58, 77, 253, 0.1);
  }
  .component-controls.flex-container {
    width: 147px;
    align-content: center;
    span {
      margin-left: auto;
    }
    img {
      height: 20px;
      width: 20px;
      margin-left: 8px;
      align-self: center;
      cursor: pointer;
    }
  }
}
.video-content {
  max-height: 136px;
  // max-width: 549px;
  min-width: 549px;

  .image-placeholder {
    position: absolute;
    left: 50%;
    min-height: 91px;
    min-width: 349px;
    transform: translate(-50%);
  }
}

.content-section {
  flex-direction: column;
  margin-left: 20px;
}
.only-images,
.section-title {
  min-height: 64px;
  height: 64px;
  .content-section,
  .image-placeholder {
    display: none;
  }
  .legend{
      font-size: 19.64px;
      color: #0a1554;
      font-weight: bold;
  }
}

.paragraph,
.video-content,
.interactive-lesson,
.interactive-map,
.single-image {
  .content-section {
    display: none;
  }
}

.interactive-map {
  .content-section,
  .image-placeholder {
    display: none;
  }
}

.single-image .image-placeholder {
  min-width: 500px;
  margin: 22px auto;
}

.left-side {
  order: 0;
  margin-right: unset;
}
.right-side {
  order: 1;
  margin-left: auto;
  margin-right: 24px;
}
.text-component-layout-fix.text-content {
  display: flex !important;
}
</style>
