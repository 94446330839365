var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "lesson-component-layout flex-container text-component-layout-fix",
      class: _vm.component.paragraph.type
    },
    [
      _c("span", { staticClass: "component-count" }, [
        _vm._v(_vm._s(_vm.componentCount))
      ]),
      _c("div", {
        staticClass: "image-placeholder",
        class: [_vm.component.paragraph.imagesOrientation]
      }),
      _vm._m(0),
      _c("span", { staticClass: "legend centered" }, [
        _vm._v(_vm._s(_vm.$t(_vm.component.paragraph.text)))
      ]),
      _c("div", { staticClass: "component-controls flex-container" }, [
        _c("img", {
          style: { opacity: _vm.arrowUpDisabledStyle },
          attrs: { src: _vm.arrowUpIcon },
          on: {
            click: function($event) {
              _vm.changeComponentOrder(_vm.component.order - 1)
            }
          }
        }),
        _c("img", {
          style: { opacity: _vm.arrowDownDisabledStyle },
          attrs: { src: _vm.arrowDownIcon },
          on: {
            click: function($event) {
              _vm.changeComponentOrder(_vm.component.order + 1)
            }
          }
        }),
        _c(
          "span",
          {
            on: {
              click: function($event) {
                _vm.deleteComponent(_vm.component)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("delete")))]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "content-section flex-container" }, [
      _c("div", { staticClass: "title-placeholder" }),
      _c("div", { staticClass: "text-placeholder" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }