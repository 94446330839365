<template>
  <div class="lesson-builder-content-section flex-container">
    <div class="components-container flex-container custom-scrollbar">
      <lesson-banner />
      <component
        v-for="(component, index) in components"
        :is="component.paragraph.type"
        :key="index"
        :component="component"
        :componentCount="index + 1"
      />
    </div>
    <properties-panel :save-component="saveLesson" />
  </div>
</template>
<script>
import PropertiesPanel from "@/components/BackOffice/PropertiesPanel.vue";
import SectionTitleComponent from "@/components/BackOffice/ContentComponents/SectionTitleComponent.vue";
import CardComponent from "@/components/BackOffice/ContentComponents/CardComponent.vue";
import TextContentComponent from "@/components/BackOffice/ContentComponents/TextContentComponent.vue";
import VideoContentComponent from "@/components/BackOffice/ContentComponents/VideoContentComponent.vue";
import ParagraphComponent from "@/components/BackOffice/ContentComponents/ParagraphComponent.vue";
import LessonBannerComponent from "@/components/BackOffice/ContentComponents/LessonBannerComponent.vue";
import InteractiveMapComponent from "@/components/BackOffice/ContentComponents/InteractiveMapComponent.vue";
import OnlyImagesComponent from "@/components/BackOffice/ContentComponents/OnlyImagesComponent.vue";
import IntractiveLessonComponent from "@/components/BackOffice/ContentComponents/IntractiveLessonComponent.vue";
import GlobalConnectionsComponent from "@/components/BackOffice/ContentComponents/GlobalConnectionsComponent.vue";

export default {
  name: "LessonContentSection",
  components: {
    "properties-panel": PropertiesPanel,
    "section-title": SectionTitleComponent,
    card: CardComponent,
    paragraph: ParagraphComponent,
    "lesson-banner": LessonBannerComponent,
    "text-content": TextContentComponent,
    "video-content": VideoContentComponent,
    "interactive-map": InteractiveMapComponent,
    "only-images": OnlyImagesComponent,
    "interactive-lesson": IntractiveLessonComponent,
    "global-connections": GlobalConnectionsComponent,
  },
  data() {
    return {
      lessonUpdatedMessage: this.$t("lessonUpdatedMessage"),
      updateLessonErrorAlert: this.$t("updateLessonErrorAlert"),
    };
  },
  computed: {
    components() {
      return this.$store.state.LessonBuilderComponents;
    },
    component() {
      return this.$store.state.currentBackOfficeComponent;
    },
  },
  methods: {
    addAlerts(component) {
      component.errors.forEach((error) => { 
        let errorNotification = this.$store.state.snackBarNotifications.find(
          (e) => e.message === error
        );
        if (!errorNotification) {
          this.$store.commit("addSnackBarNotification", {
            message: error,
            dismissable: true,
          });
        }
      });
    },
    validateComponentFields(component) {
      if (component.paragraph && component.paragraph.errors.length > 0) {
        this.addAlerts(component.paragraph);
        throw "";
      }
    },
    saveComponent(component) {
      this.validateComponentFields(component);
      if (component.paragraph.audio)
        this.setComponentPropertyValue(
          component.paragraph,
          component.paragraph.audio.id,
          "audioId"
        );

      component.paragraph.type != "lesson-banner"
        ? this.saveLessonBuilderComponent(component)
        : this.saveLessonBannerComponent(component.paragraph);
    },
    saveLesson() {
      let lessonBannerComponent = {};
      lessonBannerComponent.paragraph = this.$store.state.lessonBannerComponent; 
      try {
        if (!this.components) return;
        this.components.map((e) => {
          this.saveComponent(e);
        });
        this.saveComponent(lessonBannerComponent);
        this.$store.commit("addSnackBarNotification", {
          message: this.lessonUpdatedMessage,
          dismissable: false,
        });
      } catch (error) {
        if (error != "") {
          // eslint-disable-next-line
          console.log(typeof error, error);
          this.$store.commit("addSnackBarNotification", {
            message: error,
            dismissable: true,
          });
        }
      }
    },
    setComponentPropertyValue(component, value, propertyName) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: component,
        propertyName: propertyName,
        value: value,
      });
    },
    async saveLessonBuilderComponent(component) {
      component.paragraph.images.map((e) => {
        this.$store.dispatch("updateImageDetails", e).catch(() => {
          throw this.updateLessonErrorAlert;
        });
      });
      component.paragraph.routePoints.forEach((e) => {
        if (e.audio) this.setComponentPropertyValue(e, e.audio.id, "audioId");
        this.$store.dispatch("updateRoutePoint", e);
      });

      try {
        if (
          component.paragraph.type === "paragraph" ||
          component.paragraph.type === "text-content"
        ) {
          await this.saveEssentialQuestion(component.paragraph);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log("failed to save essential question:", component);
      }
      this.$store
        .dispatch("updateLessonBuilderComponent", component)
        .catch(() => {
          throw this.updateLessonErrorAlert;
        });
    },
    saveLessonBannerComponent(component) {
      this.saveEssentialQuestion(component);
      this.$store.dispatch("updateLessonBanner", component).catch(() => {
        throw this.updateLessonErrorAlert;
      });
    },
    async saveEssentialQuestion(component) {
      if (!component.essentialQuestion) return;
      let essentialQuestionAction = !component.essentialQuestionId
        ? "createEssentialQuestion"
        : "updateEssentialQuestion" || "";
      if (essentialQuestionAction) {
        this.$store
          .dispatch(essentialQuestionAction, component.essentialQuestion)
          .then((response) => {
            if (component.essentialQuestion.id) return;
            let essentialQuestion = { ...component.essentialQuestion };
            essentialQuestion.id = response.data;
            
            this.setComponentPropertyValue(
              component,
              response.data,
              "essentialQuestionId"
            );
            this.setComponentPropertyValue(
              component,
              essentialQuestion,
              "essentialQuestion"
            );
          });
        this.setComponentPropertyValue(
          component,
          component.essentialQuestion.id,
          "essentialQuestionId"
        );
      }
  
    },
  },
  created() {
    this.$store.commit("updateCurrentBackOfficeComponent", undefined);
  },
};
</script>
<style>
.content-component-count {
  margin-top: 5px;
  margin-left: -15px;
  margin-right: 10px;
  font-weight: bold;
  overflow: visible;
  position: absolute;
  z-index: 999;
}
</style>